import { IService } from '../../blocks/ServiceList/interfaces';
import { ISpeak } from '../../blocks/WeAreSpeaking/interfaces';
import { IReview } from '../../components/SliderReviews/interfaces';
import { IModxReview, IModxService, IModxTalk } from '../../redux/services/InterfacesModx';

export const formatReviews = (reviews: IModxReview[]): IReview[] => {
  const result: IReview[] = [];
  reviews.forEach(({ text, person, company, tags }) => {
    result.push({
      text,
      author: person,
      company,
      site: '',
    });
  });

  return result;
};

export const formatTalks = (talks: IModxTalk[]): ISpeak[] => {
  const result: ISpeak[] = [];

  talks.forEach(({ anchor, title, url }) => {
    result.push({
      text: title,
      link: url,
      textLink: anchor,
    });
  });

  return result;
};

export const formatServices = (services: IModxService[]): IService[] => {
  const result: IService[] = [];

  services.forEach(({ name, description, image }) => {
    result.push({
      title: name,
      text: description,
      image,
    });
  });

  return result;
};
