import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

export const goAnimationServices = (heightItem: string) => {
  ScrollTrigger.normalizeScroll(false);
  gsap.registerPlugin(ScrollTrigger);

  setTimeout(() => {
    const items = document.querySelectorAll('.service-list__item');
    items.forEach((x, i) => {
      if (i === 0) {
        (x as HTMLElement).style.height = '372px';
        return;
      }

      gsap.to(x, {
        scrollTrigger: {
          scrub: true,
          // markers: true,
          trigger: x,
          start: () => '0 60%',
          end: () => `+=100 50%`,
          onEnter: () => {
            if (i === 1) {
              (document.querySelector('.sticky__el') as HTMLElement).classList.add('sticky__el_safari');
            }
          },
          onLeave: () => {
            if (i === items.length - 1) {
              (document.querySelector('.sticky__el') as HTMLElement).classList.remove('sticky__el_safari');
            }
          },
        },
        duration: 1,
        height: heightItem,
      });
    });

    // const cont = document.querySelector('.service-list__container-sticky');

    // gsap.to(cont, {
    //   scrollTrigger: {
    //     scrub: true,
    //     markers: true,
    //     trigger: document.querySelectorAll('.service-list__item')[5],
    //     start: () => '0 40%',
    //     // end: () => `+=100 50%`,
    //   },
    //   duration: 1,
    //   y: -100,
    // });
  }, 2000);
};
