import * as React from 'react';
import './TextTwoColumnIcon.scss';
import {baseURLModx} from '../../redux/services/APIRequestsModx';

type TextPropsType = {
  icon: string;
  text: string;
};

type TextTwoColumnPropsType = {
  title?: string;
  subtitle?: string;
  text?: Array<TextPropsType>;
};

export function TextTwoColumnIcon({title, subtitle, text}: TextTwoColumnPropsType) {
  return (
    <div className="text-column-icon content">
      <div className="text-column-icon__container">
        <div className="text-column-icon__row">
          <div className="text-column-icon__col">
            <h3 className="text-column-icon__title">{title}</h3>
            <p className="text-column-icon__subtitle">{subtitle}</p>
          </div>
          {text ? (
            <div className="text-column-icon__text">
              {text.map((item, i) => (
                <div className="text-column-icon__item" key={i}>
                  <div className="text-column-icon__item-icon" style={{backgroundImage: `url(${baseURLModx}/${item.icon})`}}></div>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
