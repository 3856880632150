import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { Props } from './interfaces';
import './ServiceList.scss';
import './ServiceList-media.scss';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { goAnimationServices } from './functions';
import { Sticky } from '../../components/Sticky/Sticky';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const ServiceList = ({ title, list, height, widthWindow }: Props) => {
  const container: any = useRef();
  useGSAP(
    () => {
      if (!isSafari && widthWindow > 720) goAnimationServices('85px');
    },
    { scope: container }
  );

  const content = list.map(({ title, text, image }, i) => (
    <div className="service-list__item" key={i}>
      <div className="service-list__item-container">
        <h3 className="service-list__item-title">{title}</h3>
        <p className="body1 service-list__item-text">{text}</p>
        {image ? <img src={`${baseURLModx}/${image}`} className="service-list__item-image" alt="" /> : null}
      </div>
    </div>
  ));

  return (
    <div className="service-list" ref={container}>
      <h2 className="service-list__title">{title}</h2>

      <div className="service-list__container-sticky">
        {!isSafari ? (
          <Sticky
            height={height}
            containerWidth={widthWindow > 720 ? 'calc(100% - 80px)' : 'calc(100% - 32px)'}
            top="20px"
          >
            {content}
          </Sticky>
        ) : (
          content
        )}
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(ServiceList));
