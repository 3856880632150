import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, Autoplay, Pagination, TypeSwiper } from '../../services/ImportsSwiper';
import { Props } from './interfaces';
import './SliderGallery.scss';
import './SliderGallery-media.scss';
import { baseURLModx } from '../../redux/services/APIRequestsModx';

const SliderGallery = ({ title, slides, countDotsSlides, subtitle }: Props) => {
  const [numActiveSlide, setNumActiveSlide] = useState(0);

  const Changed = (current: TypeSwiper) => setNumActiveSlide(current.activeIndex);

  useEffect(() => {
    if (slider && sliderPagination) {
      slider.slideTo(numActiveSlide, 500);
      sliderPagination.slideTo(numActiveSlide, 500);
    }
  }, [numActiveSlide]);

  const [slider, setSlider] = useState<TypeSwiper>();
  const [sliderPagination, setSliderPagination] = useState<TypeSwiper>();

  const settingsAll = {
    autoplay: false,
    speed: 500,
    modules: [Autoplay, Pagination],
    onSlideChange: Changed,
  };

  const settings = {
    ...settingsAll,
    spaceBetween: 20,
    className: 'slider-gallery',
    slidesPerView: 1,
  };

  const settingsPaginationSlider = {
    ...settingsAll,
    spaceBetween: 8,
    className: 'slider-gallery-dots',
    centeredSlides: true,
    slidesPerView: countDotsSlides || 5,
    slideToClickedSlide: true,
  };

  const prevSlide = () => {
    if (slider && sliderPagination) {
      slider.slidePrev(500, false);
      sliderPagination.slidePrev(500, false);
    }
  };
  const nextSlide = () => {
    if (slider && sliderPagination) {
      slider.slideNext(500, false);
      sliderPagination.slideNext(500, false);
    }
  };

  return (
    <>
      <div className="slider-gallery__header">
        {title ? <h2 className="slider-gallery__title">{title}</h2> : null}
        {subtitle ? <p className="slider-gallery__subtitle">{subtitle}</p> : null}
      </div>
      {slides.length ? (
        <div className="slider-gallery">
          <Swiper {...settings} onInit={(slider: TypeSwiper) => setSlider(slider)}>
            {slides.map((item, i) => (
              <SwiperSlide key={i}>
                <img src={`${baseURLModx}/${item.image}`} className="slider-gallery__image" alt="" />
              </SwiperSlide>
            ))}

            <div className="slider-gallery__arrows">
              <div className="slider-gallery__arrow slider-gallery__arrow_prev" onClick={prevSlide}></div>
              <div className="slider-gallery__arrow slider-gallery__arrow_next" onClick={nextSlide}></div>
            </div>
          </Swiper>

          <div className="slider-gallery__dots">
            <Swiper
              {...settingsPaginationSlider}
              onInit={(sliderPagination: TypeSwiper) => setSliderPagination(sliderPagination)}
            >
              {slides.map((item, i) => (
                <SwiperSlide key={i}>
                  <div
                    style={{ backgroundImage: `url(${baseURLModx}/${item.image})` }}
                    className="slider-gallery__dot"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SliderGallery;
