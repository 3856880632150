import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './PageProjects.scss';
import './PageProjects-media.scss';
import { Props } from './interfaces';
import DocumentMeta from 'react-document-meta';
import Projects from '../../blocks/Projects/Projects';
import { useEffect, useState } from 'react';
import { ServiceData } from '../../redux/services/ServiceRedux';
import { IModxProjectsPage } from '../../redux/services/InterfacesModx';
import { formatFilters, formatProjects } from './functions';
import { ISeo } from '../../services/Interfaces';
import { STUB_PROJECTS } from './constants';

const PageProjects = ({ lang, alertLoaded, widthWindow }: Props) => {
  const [seo, setSeo] = useState<ISeo>();
  const [data, setData] = useState<IModxProjectsPage>(STUB_PROJECTS);
  const {
    object: {
      cases,
      page: { filter_industries, filter_services },
    },
  } = data;

  useEffect(() => {
    ServiceData.getProjectsPage({ alertLoaded, success, en: lang === 'En' });
  }, [lang]);

  const success = (data: IModxProjectsPage) => {
    setData(data);

    const { SEO_TITLE, SEO_DESCR } = data.object.page;
    setSeo({ title: SEO_TITLE, description: SEO_DESCR });
  };

  return (
    <div className="page-projects">
      <DocumentMeta {...seo} />

      <Projects
        list={formatProjects(cases)}
        filtersIndustry={formatFilters(filter_industries, 'industry', lang)}
        filtersType={formatFilters(filter_services, 'type', lang)}
      />
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(PageProjects));
