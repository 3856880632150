import React from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './BaseLink.scss';
import { SvgArrow } from '../../services/Constants';
import { Props } from './interfaces';
import { Link } from 'react-router-dom';

const BaseLink = ({ link, text, textClass = 'body1', out = false, color = 'dark' }: Props) => {
  return out ? (
    <a href={link} target="_blank" className={`${textClass} link-out ${color ? `link-out_${color}` : ''}`}>
      {text}
      {SvgArrow}
    </a>
  ) : (
    <Link to={link} className={`${textClass} link-out ${color ? `link-out_${color}` : ''}`}>
      {text}
      {SvgArrow}
    </Link>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(BaseLink));
