import { Autoplay } from 'swiper/modules';

export const settings = {
  autoplay: { delay: 0 },
  speed: 1500,
  loop: true,
  spaceBetween: 40,
  modules: [Autoplay],
  className: 'slider-runner',
  allowTouchMove: false,
  touchStartPreventDefault: false,
};
