export const MetaTags = {
  policy: {
    title: '',
    description: '',
  },
};

export const LinkVk = 'https://vk.com/';
export const LinkYoutube = 'https://rutube.ru/channel/15873659/';
export const LinkInst = 'https://www.instagram.com';

export const LinkTg = 'https://t.me/mtg_agency';
export const LinkWhatsapp = '';
export const LinkDzen = 'https://dzen.ru/';

export const CookieFlag = 'cookiesMtg';

export const SvgArrow = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 1H13M13 1L1 13M13 1V11" stroke="#141414" strokeWidth="1.5" />
  </svg>
);
