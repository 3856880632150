import { ProjectState } from '../initState/InitState';

const reducer = (state: any = ProjectState, action: { type: string; payload: object }) => {
  switch (action.type) {
    case 'HEADER_LOADED':
      return { ...state, header: { ...state.header, ...action.payload } };
    case 'ALERT_LOADED':
      return { ...state, alert: action.payload };
    case 'ARTICLE_LOADED':
      return { ...state, article: action.payload };
    case 'WIDTH_WINDOW_LOADED':
      return { ...state, widthWindow: action.payload };
    case 'OFFSET_CONTENT_LOADED':
      return { ...state, offsetContent: action.payload };
    case 'LANG_LOADED':
      return { ...state, lang: action.payload };
    case 'CURRENT_CASE_IDS_LOADED':
      return { ...state, currentCaseIds: action.payload };
    default:
      return state;
  }
};

export default reducer;
