import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './SuccessContent.scss';
import './SuccessContent-media.scss';
import { Lang } from '../../services/Interfaces';

const SuccessContent = ({ refCss, lang }: { refCss?: any; lang: Lang }) => {
  return (
    <div ref={refCss} className="success-content">
      <h3 className="success-content__text">
        {lang === 'En'
          ? 'Your application has been sent, we will contact you shortly'
          : 'Заявка отправлена, свяжемся с вами в ближайшее время'}
      </h3>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(SuccessContent));
