import './NextProject.scss';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { Link } from 'react-router-dom';

type NextProjectPropsType = {
  nextId?: string;
  nextPreview?: string;
};

export const NextProject = ({ nextId, nextPreview }: NextProjectPropsType) => {
  return (
    <div className="next-project">
      <div className="next-project__container">
        <a href={`${baseURLModx}/case/${nextId}`} className="items-wrap">
          <span className="items marquee">
            <span className="item">Следующий проект</span>
            <span className="item">Следующий проект</span>
            <span className="item">Следующий проект</span>
          </span>
          <span aria-hidden="true" className="items marquee">
            <div className="item">Следующий проект</div>
            <span className="item">Следующий проект</span>
            <span className="item">Следующий проект</span>
          </span>
        </a>
      </div>

      <div className="next-project__preview">
        <img src={`${baseURLModx}/${nextPreview}`} alt="" />
      </div>
    </div>
  );
};
