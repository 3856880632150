import * as React from 'react';
import './NumbersGrid.scss';

type TextPropsType = {
  text1: string;
  text2: string;
};

type TextTwoColumnPropsType = {
  title?: string;
  subtitle?: string;
  text?: Array<TextPropsType>;
};

export function NumbersGrid({title, subtitle, text}: TextTwoColumnPropsType) {
  return (
    <div className="number-grid-section content">
      <div className="number-grid-section__container">
        <div className="number-grid-section__head">
          <h2 className="number-grid-section__title">{title}</h2>
          <p className="number-grid-section__subtitle">{subtitle}</p>
        </div>
        {text ? (
          <div className="number-grid-section__grid">
            {text.map((item, i) => (
              <div className="number-grid-section__item" key={i}>
                <span className="number-grid-section__number">{item.text1}</span>
                <div className="number-grid-section__text">
                  <span>{item.text1}</span>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
