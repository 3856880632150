import { IModxContactsPage } from '../../redux/services/InterfacesModx';

export const STUB_CONTACTS: IModxContactsPage = {
  object: {
    id: '',
    seo: {
      title: '',
      description: '',
    },
    contacts_block: {
      address: '',
      copyright: '',
      email: '',
      phone: '',
      rutube: '',
      tg: '',
      title: '',
    },
  },
};
