import React from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './WeAreSpeaking.scss';
import './WeAreSpeaking-media.scss';
import { Props } from './interfaces';
import { SvgArrow } from '../../services/Constants';
import { avatars } from './constants';
import { AdaptiveValue } from '../../services/GlobalFunctions';

const WeAreSpeaking = ({ title = 'Мы говорим', list, widthWindow }: Props) => {
  return (
    <div className="we-are-speaking">
      <div className="we-are-speaking__content">
        <div className="we-are-speaking__title">
          <h2>{title}</h2>
          <div className="we-are-speaking__avatars">
            {avatars.map((ava, i) => (
              <div
                className="we-are-speaking__avatar"
                style={{
                  backgroundImage: `url(${ava})`,
                  left: AdaptiveValue({ widthWindow, value: `${56 * i}px` }),
                }}
                key={i}
              />
            ))}
          </div>
        </div>

        <div className="we-are-speaking__list">
          {list.map(({ text, link, textLink }, i) => (
            <a href={link} target="_blank" className="we-are-speaking__card" key={i}>
              <p className="subtitle1" dangerouslySetInnerHTML={{ __html: text }} />
              <p className="body1">
                {textLink}
                {SvgArrow}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(WeAreSpeaking));
