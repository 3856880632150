import { IModxProjectsPage } from '../../redux/services/InterfacesModx';

export const STUB_PROJECTS: IModxProjectsPage = {
  object: {
    cases: [],
    page: {
      filter_industries: [],
      filter_services: [],
      SEO_TITLE: '',
      SEO_DESCR: '',
    },
    total: 0,
  },
};
