import './ParallaxTitle.scss';
import './ParallaxTitle-media.scss';
import { Props } from './interfaces';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { goAnimationMainProjects } from './functions';

export const ParallaxTitle = ({ title }: Props) => {
  const container: any = useRef();
  useGSAP(
    () => {
      goAnimationMainProjects();
    },
    { scope: container }
  );

  return (
    <div className="parallax-title" ref={container}>
      <div className="parallax-title__slide">
        <div className="parallax-title__gap" />
        <h2 className="parallax-title__value">{title}</h2>
      </div>
    </div>
  );
};
