import { IModxMainPage, IModxServiceBlock } from '../../redux/services/InterfacesModx';

export const STUB_MAIN: IModxMainPage = {
  object: {
    id: '',
    seo: {
      title: '',
      description: '',
    },
    main_screen: {
      title: '',
      main_button_text: '',
      show_main_button: false,
      secondary_button_text: '',
      show_secondary_button: false,
      tags: '',
    },
    mtg_block: {
      title: '',
      photos: [],
    },
    about_block: {
      testimonials: [],
    },
    talk_block: {
      title: '',
      photos: [],
      links: [],
    },
  },
};

export const STUB_SERVICES_MAIN: IModxServiceBlock = {
  title: '',
  services: [],
};

export const STUB_ABOUT: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((item) =>
  require(`./img/about/${item}.png`)
);
