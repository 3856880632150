import { ReactNode } from 'react';
import './Sticky.scss';

type Props = {
  children: ReactNode;
  height?: string;
  containerWidth?: string;
  top?: string;
};

export const Sticky = ({ children, height = '3000px', containerWidth = '100%', top = '0' }: Props) => {
  return (
    <div className="sticky" style={height ? { height } : {}}>
      <div className="sticky__container" style={{ height, width: containerWidth }}>
        <div className="sticky__el" style={{ top }}>
          {children}
        </div>
      </div>
    </div>
  );
};
