import React, { useState } from 'react';
import { Swiper, SwiperSlide, Autoplay, Pagination, TypeSwiper } from '../../services/ImportsSwiper';
import { Props } from './interfaces';
import './SliderReviews.scss';
import './SliderReviews-media.scss';
import PaginationBlock from '../Pagination/Pagination';

const SliderReviews = ({ title, slides }: Props) => {
  const [numActiveSlide, setNumActiveSlide] = useState(1);

  const Changed = (current: TypeSwiper) => setNumActiveSlide(current.realIndex + 1);

  const [slider, setSlider] = useState<TypeSwiper>();
  const settings = {
    autoplay: false,
    speed: 500,
    loop: true,
    modules: [Autoplay, Pagination],
    slidesPerView: 1,
    onSlideChange: Changed,
    className: 'slider-reviews',
    spaceBetween: 20,
    autoHeight: true,
  };

  const prevSlide = () => {
    if (slider) {
      slider.slidePrev(500, false);
    }
  };
  const nextSlide = () => {
    if (slider) {
      slider.slideNext(500, false);
    }
  };

  return slides.length ? (
    <div className="reviews">
      <div className="reviews__left-block">
        {title ? <h3 className="reviews__title">{title}</h3> : null}

        <div className="slider-reviews__dots">
          <PaginationBlock current={numActiveSlide} all={slides.length} />
          <div className="slider-reviews__arrow slider-reviews__arrow_prev" onClick={prevSlide}></div>
          <div className="slider-reviews__arrow slider-reviews__arrow_next" onClick={nextSlide}></div>
        </div>
      </div>

      <Swiper {...settings} onInit={(slider: TypeSwiper) => setSlider(slider)}>
        {slides.map(({ text, author, company, site }, i) => (
          <SwiperSlide key={i}>
            <div className="slider-reviews__slide" key={i}>
              <h3 className="slider-reviews__text" dangerouslySetInnerHTML={{ __html: text }}></h3>
              <p className="subtitle1 slider-reviews__author">{author}</p>
              <p className="body1 slider-reviews__organization">
                {company}
                {company && site ? `, ` : ''}
                {site}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};

export default SliderReviews;
