import { Props } from './interfaces';
import './ContentForMedia.scss';
import './ContentForMedia-media.scss';

const ContentForMedia = ({ title, text, buttonsBlock, mb = '128px' }: Props) => {
  return (
    <div className="content-for-media" style={mb ? { marginBottom: mb } : {}}>
      <h1 className="content-for-media__title">{title}</h1>
      {text ? <p className="subtitle1 content-for-media__text">{text}</p> : null}

      <div className="content-for-media__buttons">{buttonsBlock}</div>
    </div>
  );
};

export default ContentForMedia;
