import { IListProjects } from '../../blocks/Projects/interfaces';
import { ITabCategory } from '../../components/Tabs/interfaces';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { IModxFilter, IModxProject } from '../../redux/services/InterfacesModx';
import { Lang } from '../../services/Interfaces';

export const formatProjects = (projects: IModxProject[]): IListProjects[] => {
  const result: IListProjects[] = [];

  projects.forEach(({ id, main_screen: { preview, title, industries, services } }) => {
    result.push({
      image: `${baseURLModx}/${preview}`,
      link: id.toString(),
      title,
      industries,
      services,
    });
  });

  return result;
};

export const formatFilters = (filters: IModxFilter[], type: string, lang: Lang): ITabCategory[] => {
  const result: ITabCategory[] = [{ text: lang === 'En' ? 'all' : 'все', tab: `all-${type}` }];

  filters.forEach(({ MIGX_id, tag }) => {
    result.push({
      text: tag,
      tab: tag,
    });
  });

  return result;
};
