import React from 'react';
import './MailTo.scss';
import { Props } from './interfaces';

const MailTo = ({ mail, className }: Props) => {
  return (
    <a
      className={`${className} mail-to`}
      href={`mailto:${mail}`}
      target="_blank"
      dangerouslySetInnerHTML={{ __html: mail }}
    />
  );
};

export default MailTo;
