import { Props } from './interfaces';
import './ModalFullScreen.scss';

export const ModalFullScreen = ({ children, onClose }: Props) => {
  return (
    <div className="modal-full-screen">
      <div className="modal-full-screen__close" onClick={onClose} />
      {children}
    </div>
  );
};
