import { useEffect, useState } from 'react';
import Parallax from 'parallax-js';
import './ParallaxBlock.scss';
import { Props } from './interfaces';
import { ICardLink } from '../../components/CardLink/interfaces';
import { Link } from 'react-router-dom';
import { EnumUrlsPages } from '../../services/Enum';
import { ParallaxTitle } from '../ParallaxTitle/ParallaxTitle';
import Button from '../../components/Button/Button';

export const ParallaxBlock = ({ title, list, textButton, linkButton }: Props) => {
  const [duoList, setDuoList] = useState<ICardLink[][]>([]);

  useEffect(() => {
    const scene = document.getElementById('scene');

    if (scene) {
      const parallax = new Parallax(scene, {
        selector: '.layer',
      });
    }

    const parallTest = () => {
      const parallaxBlocks = document.querySelectorAll('[class*=parallaxBlock]');
      parallaxBlocks.forEach(function (block: any) {
        const speed = 0.5;
        const yPos = window.pageYOffset * speed;
        block.style.backgroundPosition = 'center ' + yPos + 'px';
      });
    };

    window.addEventListener('scroll', parallTest);

    return window.removeEventListener('scroll', parallTest);
  }, []);

  useEffect(() => {
    const duoList = [];
    for (let i = 0; i < list.length; i += 2) {
      duoList.push([list[i], list[i + 1]]);
    }

    setDuoList(duoList);
  }, [list]);

  return (
    <div className="parallax">
      <div id="scene"></div>

      {title ? <ParallaxTitle title={title} /> : null}

      <div className="parallax__content">
        {duoList.map(([item1, item2], i) => (
          <div className="parallax__duo" key={i}>
            <Link
              to={`${EnumUrlsPages.case}/${item1.link}`}
              className="parallax__item"
              style={{ backgroundImage: `url(${item1.image}` }}
            >
              <p className="parallax__card-title">{item1.title}</p>
            </Link>

            {item2 ? (
              <Link
                to={`${EnumUrlsPages.case}/${item2.link}`}
                className="parallax__item"
                style={{ backgroundImage: `url(${item2.image})` }}
              >
                <p className="parallax__card-title">{item2.title}</p>
              </Link>
            ) : null}
          </div>
        ))}

        <div className="parallax__button">
          <Button text={textButton} link={linkButton} />
        </div>
      </div>
    </div>
  );
};
