import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

export const goAnimationAbout = (container: HTMLElement) => {
  ScrollTrigger.normalizeScroll(false);
  gsap.registerPlugin(ScrollTrigger);

  setTimeout(() => {
    const startTrigger = container;

    if (!startTrigger) return;

    const images = container.querySelectorAll(`.about-block__image`);
    const row = 2;

    const widthImage = window.screen.width / 8;

    const mainBackground = container.querySelector(`.about-block__dark-bg`);
    const greenBackground = container.querySelector(`.about-block__green-bg`);

    images.forEach((x, i) => {
      gsap
        .timeline()
        .add(
          // разлетаются фотографии
          gsap.to(x, {
            scrollTrigger: {
              scrub: true,
              // markers: { startColor: 'yellow' },
              trigger: startTrigger,
              start: () => `0 10%`,
              end: () => `1500 0%`,
            },
            duration: 3,
            x: calculatePositionX(i, widthImage),
            y: calculatePositionY(i),
            width: `${widthImage}px`, //'160px',
          })
        )
        .add(
          // фотографии становятся бесцветными
          gsap.to(x, {
            scrollTrigger: {
              scrub: true,
              // markers: true,
              trigger: startTrigger,
              start: () => `1000 10%`,
              end: () => `1500 0%`,
            },
            duration: 3,
            opacity: 0,
          })
        );
    });

    gsap
      .timeline()
      .add(
        // бесцветный фон-окружность становится зеленым
        gsap.to(greenBackground, {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `500 10%`,
            end: () => `1000 0%`,
          },
          duration: 3,
          backgroundColor: '#45AB34',
        })
      )
      .add(
        // появляются тексты и кнопка
        gsap.to(
          [
            container.querySelector(`.about-block__title`),
            container.querySelector(`.about-block__link`),
            container.querySelector(`.about-block__link-text`),
          ],
          {
            scrollTrigger: {
              scrub: true,
              // markers: true,
              trigger: startTrigger,
              start: () => `1700 10%`,
              end: () => `2000 0%`,
            },
            duration: 3,
            opacity: 1,
          }
        )
      )
      .add(
        // увеличивается текст
        gsap.to(container.querySelector(`.about-block__title`), {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `1700 10%`,
            end: () => `2000 0%`,
          },
          duration: 3,
          fontSize: '120px',
        })
      )
      .add(
        // "темный фон" из бесцветного становится темным
        gsap.to(mainBackground, {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `1000 10%`,
            end: () => `1100 0%`,
          },
          duration: 3,
          backgroundColor: '#141414',
        })
      )
      .add(
        // зеленый фон-окружность уменьшается до 0
        gsap.to(greenBackground, {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `2000 10%`,
            end: () => `2500 0%`,
          },
          duration: 3,
          width: '0',
        })
      )
      .add(
        // контейнер становится relative чтобы не наехал на ниже следующие блоки
        gsap.to(container.querySelector(`.about-block__container-info`), {
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: startTrigger,
            start: () => `2400 10%`,
            end: () => `2500 0%`,
          },
          duration: 3,
          position: 'relative',
        })
      );
    // .add(
    //   gsap.to([container.closest('.sticky__container'), container.closest('.sticky')], {
    //     scrollTrigger: {
    //       scrub: true,
    //       // markers: true,
    //       trigger: startTrigger,
    //       start: () => `3000 10%`,
    //       end: () => `3100 0%`,
    //     },
    //     duration: 3,
    //     height: 'auto',
    //   })
    // )
    // .clear();
  }, 2000);
};

const calculatePositionX = (i: number, widthImage: number) => {
  const arr = [-1, -0.6, -0.3, 0, 0.3, 0.6, 1, -1, -0.6, -0.3, 0, 0.3, 0.6, 1];

  const halfScreenWidth = (window.screen.width * 0.8) / 2;

  return arr[i] * halfScreenWidth;
};

const calculatePositionY = (i: number) => {
  const arr = [-0.9, -0.7, -0.5, -1, -0.7, -0.5, -0.9, 1, 0.5, 1, 0.7, 0.3, 1, 0.9];

  const halfScreenHight = (window.screen.height * 0.8) / 2;
  console.log(arr[i] * halfScreenHight);
  return arr[i] * halfScreenHight * 0.9;
};
