import { useEffect, useRef, useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import Input from '../../components/Input/Input';
import BaseLink from '../../components/BaseLink/BaseLink';
import { EnumUrlsPages } from '../../services/Enum';
import './PageContacts.scss';
import './PageContacts-media.scss';
import { MediaContent, ValidationFormItems } from '../../services/GlobalFunctions';
import Content from '../../components/Content/Content';
import Tel from '../../components/Tel/Tel';
import MailTo from '../../components/MailTo/MailTo';
import Button from '../../components/Button/Button';
import InputPhone from '../../components/Input/InputPhone';
import { ServiceData } from '../../redux/services/ServiceRedux';
import { CSSTransition } from 'react-transition-group';
import SuccessContent from '../../components/SuccessContent/SuccessContent';
import ErrorTextServer from '../../components/ErrorTextServer/ErrorTextServer';
import DocumentMeta from 'react-document-meta';
import { Props } from './interfaces';
import { IModxContactsPage } from '../../redux/services/InterfacesModx';
import { STUB_CONTACTS } from './constants';
import { IMenu } from '../../redux/initState/InterfacesState';

const PageContacts = ({ lang, alertLoaded }: Props) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');

  const [errorPhone, setErrorPhone] = useState<string | null>(null);
  const [serverText, setServerText] = useState<string | null>(null);

  const [data, setData] = useState<IModxContactsPage>(STUB_CONTACTS);
  const {
    object: {
      contacts_block: {
        address,
        copyright,
        email: adminEmail,
        phone: adminPhone,
        rutube,
        tg,
        title: adminTitle,
      },
      seo,
    },
  } = data;
  useEffect(() => {
    ServiceData.getContactsPage({ alertLoaded, success, en: lang === 'En' });
  }, [lang]);

  const success = (data: IModxContactsPage) => {
    setData(data);
  };

  const [successShow, setSuccessShow] = useState(false);
  const nodeRefSuccess = useRef(null);
  const OnTimeOut = () => {
    setTimeout(() => setSuccessShow(false), 2000);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!ValidationFormItems({ phone, setErrorPhone })) {
      return;
    }
    ServiceData.sendEmail({
      data: { name, phone, description },
      success: successSendForm,
      setServerText,
    });
  };

  const successSendForm = () => {
    setName('');
    setPhone('+7');
    setDescription('');
    setSuccessShow(true);
  };

  const title = <h2>{adminTitle}</h2>;
  const socs: IMenu[] = [
    { text: 'Telegram', link: tg },
    { text: 'Rutube', link: rutube },
  ];

  const contacts = (
    <div>
      {MediaContent(title, null)}
      <div className="page-contacts__contacts">
        <div>
          <Tel phone={adminPhone} className="body1" />
          <MailTo mail={adminEmail} className="body1" />
        </div>

        <div className="footer__socs">
          {socs.map(({ text, link }, i) => (
            <BaseLink link={link} text={text} out key={i} />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <Content
      padding={{ paddingMain: { top: 80, bottom: 0 }, paddingMob: { top: 60, bottom: 40 } }}
      block={
        <>
          <div className="page-contacts">
            <DocumentMeta {...seo} />

            {MediaContent(null, title)}
            {MediaContent(contacts, null)}

            <form onSubmit={handleSubmit}>
              <Input
                placeholder={lang === 'En' ? 'Name' : 'Имя'}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                error={null}
              />
              <InputPhone
                placeholder="+7"
                value={phone}
                onChange={(e: any) => setPhone(e.target.value)}
                error={errorPhone}
              />
              <Input
                placeholder={lang === 'En' ? 'Message' : 'Описание'}
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                error={null}
              />

              <div className="page-contacts__button">
                {serverText ? <ErrorTextServer serverText={serverText} /> : null}
                <Button text={lang === 'En' ? 'Send' : 'Отправить'} type="border" />
              </div>

              <p className="caption">
                {lang === 'En'
                  ? ` By clicking on the ‘Send’ button, you agree to the privacy policy and consent to the processing of personal data`
                  : `Нажимая на кнопку "Отправить", вы соглашаетесь с политикой конфиденциальности и даете согласие
              на обработку персональных данных`}
              </p>
            </form>

            {MediaContent(null, contacts)}

            <CSSTransition
              in={successShow}
              nodeRef={nodeRefSuccess}
              timeout={{ appear: 200, enter: 0, exit: 200 }}
              classNames="css-transition-modal"
              unmountOnExit
              onEnter={OnTimeOut}
            >
              <SuccessContent refCss={nodeRefSuccess} />
            </CSSTransition>

            <div className="page-contacts__address">
              <p className="body2">{address}</p>
            </div>

            <div>
              <a href={EnumUrlsPages.policy} className="body2 footer__policy">
                {lang === 'En' ? 'Policy' : 'Политика конфиденциальности'}
              </a>
              <p className="body2">{copyright}</p>
            </div>
          </div>
        </>
      }
    />
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(PageContacts));
